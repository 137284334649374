import * as React from "react";

import { ThemeColors } from "../helpers";

interface IProviderProps {
  name: string;
  logo: string;
  description: string;
  themeColors: ThemeColors;
  onClick: () => void;
}

export function Provider(props: IProviderProps) {
  const {
    name,
    logo,
    onClick
  } = props;
  return (
    <li className="connect-wallet__item">
      <button onClick={onClick}>
        <span>{name}</span>
        <div className="connect-wallet__item__logo">
          <img src={logo} alt={name}/>
        </div>
      </button>
    </li>
  );
}
